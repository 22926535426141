import React from 'react';
import { Router, useLocation } from '@reach/router';
import RestrictedRoute from '../components/Security/restrictedRoute';
import SuccessfullyCreated from '../components/SuccessfullyCreated';
import Layout from '../components/layouts/Layout';
import DashboardLayout from '../components/layouts/DashboardLayout';

const App = () => {
  const location = useLocation();

  return (
    <Router>
      <RestrictedRoute
        isAllowed={!!location?.state?.propertyId}
        propertyId={location?.state?.propertyId}
        isBuilding={location?.state?.isBuilding}
        isLimitExceeded={location?.state?.isLimitExceeded}
        path="/app/successfullyCreated"
        wrapper={Layout}
        wrapperProps={{ navColor: 'white' }}
        component={SuccessfullyCreated}
      />
      <RestrictedRoute
        isAllowed={!!location?.state?.propertyId}
        propertyId={location?.state?.propertyId}
        isBuilding={location?.state?.isBuilding}
        isLimitExceeded={location?.state?.isLimitExceeded}
        isEdit={location?.state?.isEdit}
        property={location?.state?.property}
        path="/app/dashboard/successfullyCreated"
        wrapper={DashboardLayout}
        wrapperProps={{ title: '' }}
        component={SuccessfullyCreated}
      />
    </Router>
  );
};

export default App;
