import { useDispatch } from 'react-redux';

import { createPayment } from '@a1-ict/fsbo/paymentSlice';

import { toast } from 'react-toastify';
import { usePrivateFunction } from '../Security/usePrivateFunction';
import { payFromBalance } from '@a1-ict/fsbo/paymentSlice/requests';
import { navigate } from 'gatsby';

export const usePayments = () => {
  const paymentTypes = {
    epay: 'paylogin',
    balance: 'pay-from-balance',
    easyPay: 'unknown',
    bank: 'bank',
  };

  const dispatch = useDispatch();
  const { secure } = usePrivateFunction();

  const generatePayment = (params) => {
    const form = document.createElement('form');
    form.setAttribute('method', 'POST');
    form.setAttribute('action', params.epay_url);

    for (let key in params) {
      const input = document.createElement('input');
      input.setAttribute('type', 'hidden');
      input.setAttribute('name', key);
      input.setAttribute('value', params[key]);
      form.appendChild(input);
    }
    document.body.appendChild(form);
    form.submit();
    document.body.removeChild(form);
  };

  const postPaymentData = async (selectedPaymentType, body, isBalance) => {
    if (isBalance) {
      const result = await dispatch(
        payFromBalance({
          paymentType: selectedPaymentType,
          body: body,
        })
      );
      return result;
    } else
      secure(async () => {
        const result = await dispatch(
          createPayment({
            paymentType: selectedPaymentType,
            body: body,
          })
        );
        if (result.payload && result.payload.ok)
          generatePayment(result.payload.data.payment_request);
        else {
          toast.error('Възникна грешка, моля опитайте отново', {
            position: toast.POSITION.BOTTOM_CENTER,
          });
        }
      });
  };

  return {
    postPaymentData: (selectedPaymentType, body, isBalance = false) => {
      return postPaymentData(selectedPaymentType, body, isBalance);
    },
    paymentTypes,
  };
};
