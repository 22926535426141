import React from 'react';
import { navigate } from 'gatsby';
import PrivateRoute from './privateRoute';

const RestrictedRoute = ({ isAllowed = false, component: Component, ...rest }) => {
  if (!isAllowed) {
    navigate('/');
    return null;
  }
  return (
    <PrivateRoute>
      <Component {...rest} />
    </PrivateRoute>
  );
};

export default RestrictedRoute;
