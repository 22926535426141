import { selectError } from '@a1-ict/core/dialogSlice';
import { editInvoiceDetails } from '@a1-ict/fsbo/userSlice';
import { hasInvoiceDetails } from '@a1-ict/fsbo/userSlice/requests';
import { selectInvoiceDetails } from '@a1-ict/fsbo/userSlice/selectors';
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { Row, Col, Label, Input } from 'reactstrap';
import { bulstatValidationRegex, cyrillicRegex, latinLettersRegex } from '../data/validationsRegex';
import { ToastDismiss } from './Common';

const InvoiceDetails = ({}) => {
  const myInvoiceDetails = useSelector(selectInvoiceDetails);
  const [toggler, setToggler] = useState(!!myInvoiceDetails.is_business);
  const [invoiceData, setInvoiceData] = useState({});
  const dispatch = useDispatch();
  const error = useSelector((state) => selectError(state, 'editInvoiceDetails_error'));

  useEffect(() => {
    setInvoiceData(myInvoiceDetails);
    setToggler(myInvoiceDetails.is_business);
  }, [myInvoiceDetails]);

  const handleInvoiceDetails = (e) => {
    const { name, value } = e.target;
    const regex = /^[0-9]*$/;
    const noNumRegex = /\d/;

    if (e.target?.getAttribute('data-type')) {
      if (e.target.getAttribute('data-type') === 'number')
        return !value.match(regex)
          ? null
          : setInvoiceData({ ...invoiceData, [name]: Number(value) });

      if (e.target.getAttribute('data-type') === 'no_numbers' && value.match(noNumRegex)) return;
    }

    setInvoiceData({ ...invoiceData, [name]: value });
  };

  const handleInvoiceEdit = async () => {
    let copyOfData = { ...invoiceData };
    for (const k in copyOfData) {
      if (copyOfData[k] === null) copyOfData[k] = '';
    }

    if (invoiceData.is_business) {
      if (
        !!invoiceData.company_invoice_name &&
        !cyrillicRegex.test(invoiceData.company_invoice_name)
      ) {
        toast.error('Името може да бъде само на кирилица', {
          position: toast.POSITION.BOTTOM_CENTER,
        });
        return;
      }
      if (
        !!invoiceData.company_invoice_owner &&
        !cyrillicRegex.test(invoiceData.company_invoice_owner)
      ) {
        toast.error('МОЛ може да бъде само на кирилица', {
          position: toast.POSITION.BOTTOM_CENTER,
        });
        return;
      }
      if (
        !!invoiceData.company_invoice_uic &&
        !bulstatValidationRegex.test(invoiceData.company_invoice_uic)
      ) {
        toast.error('Въведеният Булстат не е валиден', {
          position: toast.POSITION.BOTTOM_CENTER,
        });
        return;
      }
      if (!!!invoiceData.company_invoice_vat) {
        toast.error('Моля въведете Данъчен номер', {
          position: toast.POSITION.BOTTOM_CENTER,
        });
        return;
      }
      if (
        !!invoiceData.company_invoice_address &&
        latinLettersRegex.test(invoiceData.company_invoice_address)
      ) {
        toast.error('Адресът не може да съдържа букви на латиница', {
          position: toast.POSITION.BOTTOM_CENTER,
        });
        return;
      }
    } else {
      if (
        !!invoiceData.person_invoice_name &&
        !cyrillicRegex.test(invoiceData.person_invoice_name)
      ) {
        toast.error('Името може да бъде само на кирилица', {
          position: toast.POSITION.BOTTOM_CENTER,
        });
        return;
      }
    }

    const response = await dispatch(editInvoiceDetails({ body: copyOfData }));
    if (!!response?.payload?.ok)
      toast.success('Успешно запазване на данните!', {
        position: toast.POSITION.BOTTOM_CENTER,
      });
  };

  return (
    <>
      {' '}
      {myInvoiceDetails && (
        <Row className="  mt-2">
          <Col xs={12} className="mb-2 ">
            {' '}
            <div className="custom-control custom-switch pl-3">
              <input
                onChange={(e) => {
                  setToggler(!toggler);
                  setInvoiceData({ ...invoiceData, is_business: !toggler });
                }}
                checked={!!toggler}
                type="checkbox"
                className="custom-control-input"
                id="customSwitch1"
              />
              <label
                style={{ cursor: 'pointer' }}
                className="custom-control-label"
                htmlFor="customSwitch1"
              >
                Фактура (на фирма)
              </label>
            </div>
          </Col>
          <Col className="my-2">
            <h5 className="h5">Информация за фактура</h5>
          </Col>
          <Col xs={12} className="my-2">
            <div id="errorDiv">
              <ToastDismiss content={error} showToast={!!error} className="p-1 login-alert" />
            </div>
          </Col>

          {!toggler ? (
            <>
              <Col xs={12} sm={6} className="mb-3 ">
                <div className=" d-flex flex-column justify-content-start ">
                  <Label for="person_invoice_name" className="text-truncate">
                    Име и фамилия на кирилица
                  </Label>

                  <Input
                    id="person_invoice_name"
                    type="text"
                    name="person_invoice_name"
                    className="form-control-lg"
                    value={invoiceData.person_invoice_name ? invoiceData.person_invoice_name : ''}
                    onChange={handleInvoiceDetails}
                  />
                  <span className="animated text-small">
                    Името и фамилията могат да бъдат изписани само на кирилица
                  </span>
                </div>
              </Col>
              <Col xs={12} sm={6} className="mb-3 ">
                <div className=" d-flex flex-column justify-content-start ">
                  <Label for="person_invoice_address" className="text-truncate">
                    Адрес
                  </Label>

                  <Input
                    id="person_invoice_address"
                    type="text"
                    name="person_invoice_address"
                    className="form-control-lg"
                    value={
                      invoiceData.person_invoice_address ? invoiceData.person_invoice_address : ''
                    }
                    onChange={handleInvoiceDetails}
                  />
                </div>
              </Col>
              <Col xs={12}>
                <div className="mt-2 mb-3">
                  <h6 className="text-danger">
                    Моля, попълнете коректно всички полета. Имот.com не носи отговорност за грешно
                    подадени данни.
                  </h6>
                </div>
              </Col>
              <Col xs={12}>
                <button className="btn animated px-4 py-2 " onClick={handleInvoiceEdit}>
                  {' '}
                  <span className="font-weight-bold">Запази </span>
                </button>
              </Col>
            </>
          ) : (
            <>
              <Col xs={12} sm={6} className="mb-3 ">
                <div className=" d-flex flex-column justify-content-start ">
                  <Label for="company_invoice_name" className="text-truncate">
                    Име на фирмата
                  </Label>

                  <Input
                    id="company_invoice_name"
                    type="text"
                    name="company_invoice_name"
                    className="form-control-lg"
                    value={invoiceData.company_invoice_name ? invoiceData.company_invoice_name : ''}
                    onChange={handleInvoiceDetails}
                  />
                  <span className="animated text-small">
                    Името на фирмата може да бъде изписано само на кирилица
                  </span>
                </div>
              </Col>
              <Col xs={12} sm={6} className="mb-3 ">
                <div className=" d-flex flex-column justify-content-start ">
                  <Label for="company_invoice_owner" className="text-truncate">
                    МОЛ
                  </Label>

                  <Input
                    id="company_invoice_owner"
                    type="text"
                    name="company_invoice_owner"
                    className="form-control-lg"
                    data-type="no_numbers"
                    value={
                      invoiceData.company_invoice_owner ? invoiceData.company_invoice_owner : ''
                    }
                    onChange={handleInvoiceDetails}
                  />
                  <span className="animated text-small">
                    МОЛ може да бъде изписан само на кирилица
                  </span>
                </div>
              </Col>
              <Col xs={12} sm={6} className="mb-3 ">
                <div className=" d-flex flex-column justify-content-start ">
                  <Label for="company_invoice_uic" className="text-truncate">
                    ЕИК/Булстат
                  </Label>

                  <Input
                    id="company_invoice_uic"
                    type="text"
                    name="company_invoice_uic"
                    className="form-control-lg"
                    value={invoiceData.company_invoice_uic ? invoiceData.company_invoice_uic : ''}
                    onChange={handleInvoiceDetails}
                  />
                </div>
              </Col>
              <Col xs={12} sm={6} className="mb-3 ">
                <div className=" d-flex flex-column justify-content-start ">
                  <Label for="company_invoice_vat" className="text-truncate">
                    Данъчен номер (Рег. по ЗДДС)
                  </Label>

                  <Input
                    id="company_invoice_vat"
                    type="text"
                    name="company_invoice_vat"
                    className="form-control-lg"
                    value={invoiceData.company_invoice_vat ? invoiceData.company_invoice_vat : ''}
                    onChange={handleInvoiceDetails}
                  />
                </div>
              </Col>

              <Col xs={12} className="mb-3 ">
                <div className=" d-flex flex-column justify-content-start ">
                  <Label for="company_invoice_address" className="text-truncate">
                    Адрес
                  </Label>

                  <Input
                    id="company_invoice_address"
                    type="text"
                    name="company_invoice_address"
                    className="form-control-lg"
                    value={
                      invoiceData.company_invoice_address ? invoiceData.company_invoice_address : ''
                    }
                    onChange={handleInvoiceDetails}
                  />
                </div>
              </Col>
              <Col xs={12}>
                <div className="mt-2 mb-3">
                  <h6 className="text-danger">
                    Моля, попълнете коректно всички полета. Имот.com не носи отговорност за грешно
                    подадени данни.
                  </h6>
                </div>
              </Col>
              <Col xs={12}>
                <button className="btn animated px-4 py-2 " onClick={handleInvoiceEdit}>
                  {' '}
                  <span className="font-weight-bold">Запази </span>
                </button>
              </Col>
            </>
          )}
        </Row>
      )}
    </>
  );
};

export default InvoiceDetails;
