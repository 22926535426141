import React from 'react';
import { navigate } from 'gatsby';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import { FaRegCheckCircle } from '@react-icons/all-files/fa/FaRegCheckCircle';
import { Container, Row, Col } from 'reactstrap';

import { Link } from 'gatsby';
import PromoteProperty from './PromoteProperty';
import { setToast } from '@a1-ict/fsbo/notificationsSlice';

const PropertySubmitted = ({ isBuilding, propertyId, isLimitExceeded, isEdit, property }) => {
  const dispatch = useDispatch();

  const handleBalancePaymentAttempt = (result, _, message) => {
    if (result?.payload?.ok) {
      dispatch(
        setToast({
          showToast: true,
          message: message.success,
          type: 'success',
        })
      );
      navigate('/dashboard/my-properties', { state: { refresh: true } });
    } else
      toast.error(message.error, {
        position: toast.POSITION.BOTTOM_CENTER,
      });
  };

  return (
    <Container fluid="md" className="pt-4">
      <Row>
        <Col className="mb-5 ">
          <div className="bg-white p-2 d-flex flex-column p-4 align-items-center">
            <h4>Качването е успешно!</h4>
            <div className="my-3">
              <FaRegCheckCircle size="100" color="green"></FaRegCheckCircle>
            </div>
            <p className="my-4">
              Вашата обява е с номер <span className="color-primary ">{propertyId}</span>
            </p>
            {!isLimitExceeded && (
              <div className="text-danger font-weight-bolder">
                <p>
                  {isEdit && 'Редактирането е успешно! '}
                  Вашата обява, ще бъде активна след одобрение на администратор, в рамките на 24
                  часа. Успешна сделка!
                </p>
                {!isBuilding && (
                  <p>
                    Не пропускайте да се възползвате от <a href="/info-pages/PriceAndProperty" target="_blank">предимствата</a> на нашите Gold и Silver Обяви!
                  </p>
                )}
              </div>
            )}
            {isLimitExceeded && (
              <p className="text-danger font-weight-bolder">
                {isBuilding
                  ? ' Вие сте използвали Вашия бонус за публикуване на 1 безплатна обява от тип Нови сгради. Цената на всяка следваща обява от този тип  е 30лв. с ДДС на месец.'
                  : <span>Вие сте достигнали лимита си от обикновени безплатни обяви. За увеличение на лимита е необходимо да закупите един от нашите    <Link to="/dashboard/my-properties" className="mr-2">
                    допълнителни пакети
                  </Link>в секция “Моите обяви” във Вашия профил. Ограничението не важи за Gold и Silver обявите!</span>}
              </p>
            )}
            {!isLimitExceeded && isBuilding && !isEdit && <p>Обявата е валидна 30 дни</p>}
            {!isLimitExceeded && !isBuilding && !property.is_golden && !property.is_silver && !isEdit && <p>Обявата е валидна 7 дни</p>}
            {!isLimitExceeded && isBuilding ? null : (
              <PromoteProperty
                className="pb-3"
                propertyId={propertyId}
                isBuilding={isBuilding}
                onPaymentAttempted={handleBalancePaymentAttempt}
                property={property}
              />
            )}


            <div className="">
              <Link to="/" className="mr-2">
                <button className="btn animated">
                  <span>Начало</span>
                </button>
              </Link>
              <Link to="/dashboard/my-properties">
                <button className="btn animated">
                  <span>Моите обяви</span>
                </button>
              </Link>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

const SuccessfullyCreated = ({
  isBuilding = false,
  isEdit = false,
  propertyId,
  property = {},
  isLimitExceeded,
  wrapper: Wrapper,
  wrapperProps = {},
}) => {
  return (
    <Wrapper {...wrapperProps}>
      <PropertySubmitted
        isBuilding={isBuilding}
        propertyId={propertyId}
        property={property}
        isLimitExceeded={isLimitExceeded}
        isEdit={isEdit}
      ></PropertySubmitted>
    </Wrapper>
  );
};

export default SuccessfullyCreated;
